import React from 'react';
import Main from '../containers/Layout';
import termsAndConditionsMerchantAppContent from '../../content/pages/terms-and-conditions-business-app.yml';
import TermsMerchantAppContainer from '@/containers/Terms-and-conditions-business-app';

export interface TermsAndConditionsMerchantAppPageContent {
  header: {
    title1: string;
    title2: string;
    subtitle1: string;
    subtitle2: string;
  };
  body: string;
}
const TermsAndConditionsMerchantAppPage: React.FunctionComponent = () => {
  const content =
    termsAndConditionsMerchantAppContent as unknown as TermsAndConditionsMerchantAppPageContent;
  return (
    <Main>
      <TermsMerchantAppContainer content={content} />
    </Main>
  );
};

export default TermsAndConditionsMerchantAppPage;
